













import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Fab from '@/components/Fab.vue';
import Products from '@/components/products/Products.vue';
import ProductAddModal from '@/components/products/ProductAddModal.vue';

@Component({
  components: {
    Fab,
    Products,
    ProductAddModal,
  },
})
export default class ProductsSettingsPage extends Vue {
  loading: boolean = false;
  showModal: boolean = false;

  onCreated(): void {
    (this.$refs['products'] as Products).refresh();
  }
}
