



































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { InvoiceVAT, LedgerNumber, Product } from '@/models';
import {
  VatCodeService,
  ProductService,
  LedgerNumberService,
} from '@/lib/services';

@Component
export default class ProductAddModal extends Vue {
  error: string = '';
  data: Product = new Product();
  vatCodes: InvoiceVAT[] = [];
  ledgerNumbers: LedgerNumber[] = [];

  async created(): Promise<void> {
    this.vatCodes = await VatCodeService.all();
    this.ledgerNumbers = await LedgerNumberService.all();
  }

  async mounted(): Promise<void> {
    this.data = new Product();
  }

  async onSubmit(): Promise<void> {
    this.error = '';
    try {
      await ProductService.create(this.data);
      this.$emit('saved');
      this.close();
    } catch (e) {
      this.error = (e as any).data.message;
    }
  }

  close(): void {
    this.$emit('close');
  }
}
