















































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButtonList, ActionButton } from '@/components/actionbuttons';

import { money as moneyFilter } from '@/lib/filters/money';

import { InvoiceVAT, Product, LedgerNumber } from '@/models';
import { ProductService } from '@/lib/services';

@Component({
  components: {
    Popover,
    ActionButtonList,
    ActionButton,
  },
  filters: {
    money: moneyFilter,
  },
})
export default class ProductLine extends Vue {
  @Prop({ required: true })
  product: Product;

  @Prop({ required: true })
  vatcodes: InvoiceVAT[];

  @Prop({ required: true })
  ledgernumbers: LedgerNumber[];

  editProduct: Product;
  editing: boolean = false;

  async doDelete(): Promise<void> {
    await ProductService.delete(this.product.id);
    this.$emit('delete');
  }

  edit(): void {
    this.editProduct = Object.assign(new Product(), this.product);
    this.editing = true;
  }

  async save(): Promise<void> {
    const product = await ProductService.update(this.editProduct);
    Object.assign(this.product, product);
    this.editing = false;
  }

  cancel(): void {
    this.editing = false;
  }

  get descriptionSnip(): string {
    if (this.product.description.length <= 40) {
      return this.product.description;
    }
    return this.product.description.slice(0, 40) + '…';
  }

  get vatName(): string {
    const vc = this.vatcodes.find((e) => e.id === this.product.vatId);
    return vc ? vc.name : 'UNKNOWN';
  }

  get ledgerNumberName(): string {
    const ln = this.ledgernumbers.find(
      (e) => e.id === this.product.ledgerNumberId,
    );
    return ln ? ln.name : 'UNKNOWN';
  }
}
