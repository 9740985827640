















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { debounce } from 'lodash-es';

import SkeletonLoader from '@/components/SkeletonLoader.vue';
import ProductLine from './ProductLine.vue';

import { clamp } from '@/lib/helpers';

import { Product, InvoiceVAT, LedgerNumber } from '@/models';
import {
  LedgerNumberService,
  ProductService,
  VatCodeService,
} from '@/lib/services';

@Component({
  components: {
    SkeletonLoader,
    ProductLine,
  },
})
export default class Products extends Vue {
  entries: Product[] = [];
  vatCodes: InvoiceVAT[] = [];
  ledgerNumbers: LedgerNumber[] = [];
  loading: boolean = true;
  limit: number = 10;
  page: number = 0;
  count: number = 0;
  totalEntries: number = 0;
  totalPages: number = 0;
  currentFirst: number = 0;
  currentLast: number = 0;

  currentSort: string = 'description';
  currentReverse: boolean = false;

  // Debounced wrapper around loadPage
  updatePage = debounce((x: number = 1) => this.loadPage(x), 500);

  async mounted(): Promise<void> {
    this.vatCodes = await VatCodeService.all();
    this.ledgerNumbers = await LedgerNumberService.all();
    await this.updatePage();
  }

  async updatePageLimit(): Promise<void> {
    this.limit = clamp(this.limit, 1, 100);
    await this.updatePage(1);
  }

  async refresh(): Promise<void> {
    this.updatePage(this.page);
  }

  async loadPage(page: number = 1): Promise<void> {
    this.loading = true;
    const results = await ProductService.search(page, {
      limit: +this.limit,
      reverse: this.currentReverse,
      order: this.currentSort,
    });

    this.entries = results.items;

    this.page = results.current;
    this.totalEntries = results.total_items;
    this.totalPages = results.last;
    this.currentFirst =
      ((this.page - 1) * results.limit + 1) * +(results.items.length > 0);
    this.currentLast = Math.min(this.page * results.limit, this.totalEntries);
    this.count = results.items.length;

    this.loading = false;
  }

  async nextPage(): Promise<void> {
    if (this.page >= this.totalPages) return;
    await this.loadPage(this.page + 1);
  }

  async prevPage(): Promise<void> {
    if (this.page <= 1) return;
    await this.loadPage(this.page - 1);
  }

  sort(column: string): void {
    if (this.currentSort === column) {
      this.currentReverse = !this.currentReverse;
    } else {
      this.currentSort = column;
      this.currentReverse = false;
    }
    this.updatePage();
  }

  sortClasses(column: string): Record<string, boolean> {
    const active = this.currentSort === column;
    return {
      sortable: true,
      'sort-active': active,
      'sort-asc': active && !this.currentReverse,
      'sort-desc': active && this.currentReverse,
    };
  }
}
